import React, { useContext, useEffect } from "react";
import GameContext from "../GameContext";
import { Container, CardItem } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const GameCards = ({ setCardMatchs, timeRemaining }) => {
  const {
    firstSelectedCard,
    setFirstSelectedCard,
    secondSelectedCard,
    setSecondSelectedCard,
    iconFoundList,
    setIconFoundList,
    iconList,
    isPaused,
    isCheckingCards,
    setIsCheckingCards,
    difficulty,
  } = useContext(GameContext);

  const onSelectCard = (index) => () => {
    if (isCheckingCards) return;
    if (firstSelectedCard === -1) setFirstSelectedCard(index);
    else setSecondSelectedCard(index);
  };

  const onEndCHeckingSelectedCards = () => {
    setFirstSelectedCard(-1);
    setSecondSelectedCard(-1);
    setIsCheckingCards(false);
  };

  const onCheckIfFoundIcon = () => {
    if (firstSelectedCard === -1) return;

    setIsCheckingCards(true);
    const firstSelectedCardIcon = iconList[firstSelectedCard]?.name;
    const secondSelectedCardIcon = iconList[secondSelectedCard]?.name;

    if (firstSelectedCardIcon === secondSelectedCardIcon) {
      setTimeout(() => {
        const iconFoundListClone = [...iconFoundList];
        iconFoundListClone.push(firstSelectedCardIcon);
        setIconFoundList(iconFoundListClone);
        setCardMatchs((prev) => prev + 1);
        onEndCHeckingSelectedCards();
      }, [1000]);
    } else {
      setTimeout(onEndCHeckingSelectedCards, [1000]);
    }
  };

  useEffect(onCheckIfFoundIcon, [secondSelectedCard]);

  return (
    <Container>
      {iconList.map((icon, index) => {
        const wasNotFound = iconFoundList.indexOf(icon.name) === -1;
        const isTheFirstSelectedCard = firstSelectedCard === index;
        const isTheSecondSelectedCard = secondSelectedCard === index;
        const onClick = onSelectCard(index);

        const isShowingFrontFace =
          isTheFirstSelectedCard || isTheSecondSelectedCard;

        return (
          <CardItem
            key={index}
            onClick={() => {
              if (timeRemaining === 0 || timeRemaining === 60) {
              } else onClick();
            }}
            isVisible={wasNotFound}
            isShowingFrontFace={isShowingFrontFace}
            disabled={timeRemaining === 0 || timeRemaining === 60}
            numOfCardsInEachLine={difficulty / 4}
          >
            <img src={icon.img} />
            {/* <FontAwesomeIcon icon={icon} /> */}
          </CardItem>
        );
      })}
    </Container>
  );
};

export default GameCards;
