import React from "react";
import CardComponent from "../components/Card/CardComponent";
import twitter from "../assets/img/icons/twitter.png";
import ether from "../assets/img/icons/ether.png";
import heroimg from "../assets/img/hero-header/hero-section.png";
import card1 from "../assets/img/card/card1.png";
import card2 from "../assets/img/card/card2.png";
import card3 from "../assets/img/card/card3.png";
import card4 from "../assets/img/card/card4.png";
import card5 from "../assets/img/card/card5.png";
import card6 from "../assets/img/card/card6.png";
import card7 from "../assets/img/card/card7.png";
import card9 from "../assets/img/card/card9.png";
import card10 from "../assets/img/card/card10.png";
import card11 from "../assets/img/card/card11.png";
import card12 from "../assets/img/card/card12.png";
import card13 from "../assets/img/card/card13.png";
import img13 from "../assets/img/card/img13.png";
import img14 from "../assets/img/card/img14.png";
import img15 from "../assets/img/card/img15.png";
import img16 from "../assets/img/card/img16.png";
import img17 from "../assets/img/card/img17.png";
import img18 from "../assets/img/card/img18.png";
import img19 from "../assets/img/card/img19.png";
import img20 from "../assets/img/card/img20.png";

import card8 from "../assets/img/card/questionMark.svg";
import Layout from "../layouts";
import bg from "../assets/img/bg-img/header-bg.png";

const FanPage = () => {
  const cards = [
    {
      name: "Donald Trump",
      amount: "1.2 Bilion",
      image: card1,
      twitter: "https://twitter.com/realDonaldTrump",
      etherscan:
        "https://etherscan.io/tx/0xd787c8e314efb1ba768cf2a1f0f035e4abd2d4f7c20b1a2c7edf105e38b402c2",
    },
    {
      name: "Snoop Dogg",
      amount: "1.2 Bilion",
      image: card2,
      twitter: "https://twitter.com/SnoopDogg",
      etherscan:
        "https://etherscan.io/tx/0x3a2f4326c3952c7f5a36b93303bd3678e561ac83f6a625eb81e21dae4dd31a30",
    },
    {
      name: "Stephen Curry",
      amount: "1.2 Bilion",
      image: card3,
      twitter: "https://twitter.com/StephenCurry30",
      etherscan:
        "https://etherscan.io/tx/0x9cd102eb1c276fe3827e38a84eebfe206a8ccb80ebfa5db681abbf9a0bdab1a4",
    },
    {
      name: "Justin Bieber",
      amount: "1.2 Bilion",
      image: card4,
      twitter: "https://twitter.com/justinbieber",
      etherscan:
        "https://etherscan.io/tx/0x1057c01c8540ae58cd22da0bb5404f13f36749d507f29912417c0e50c2564bc0",
    },
    {
      name: "Vitalik Buterin",
      amount: "1.2 Bilion",
      image: card5,
      twitter: "https://twitter.com/VitalikButerin",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Logan Paul",
      amount: "1.2 Bilion",
      image: card9,
      twitter: "https://twitter.com/LoganPaul",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Serena Williams",
      amount: "1.2 Bilion",
      image: card6,
      twitter: "https://twitter.com/serenawilliams",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Mark Cuban",
      amount: "1.2 Bilion",
      image: card7,
      twitter: "https://twitter.com/mcuban",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Joe Biden",
      amount: "47 Million",
      image: card10,
      twitter: "https://twitter.com/JoeBiden",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Hillary Clinton",
      amount: "47 Million",
      image: card11,
      twitter: "https://twitter.com/HillaryClinton",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Gary Gensler",
      amount: "47 Million",
      image: card12,
      twitter: "https://twitter.com/GaryGensler",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Elizabeth Warren",
      amount: "47 Million",
      image: card13,
      twitter: "https://twitter.com/SenWarren",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Elon Musk",
      amount: "47 Million",
      image: img13,
      twitter: "https://twitter.com/elonmusk",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Cathie Woods",
      amount: "47 Million",
      image: img14,
      twitter: "https://twitter.com/CathieDWood",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Michael Saylor",
      amount: "47 Million",
      image: img15,
      twitter:
        "https://x.com/saylor?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },

    {
      name: "Warren Buffet",
      amount: "47 Million",
      image: img16,
      twitter: "https://twitter.com/warrenbuffet",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Craig Steven Wright",
      amount: "123 Million",
      image: img17,
      twitter: "https://twitter.com/Dr_CSWright",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Satoshi Nakamoto",
      amount: "123 Million",
      image: img18,
      twitter: "https://twitter.com/Satoshi",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Hal Finney",
      amount: "123 Million",
      image: img19,
      twitter: "https://twitter.com/halfin",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    {
      name: "Ross Ulbricht",
      amount: "123 Million",
      image: img20,
      twitter: "https://twitter.com/RealRossU",
      etherscan: "https://whoisbitcoinsatoshi.wtf/fanpage",
    },
    // Add more card data as needed
  ];

  return (
    <Layout Title="Fan Page">
      <div className="Fanapp">
        <div style={{ backgroundImage: `url(${bg})` }}>
          <div
            className="header"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "100px",
            }}
          >
            <h1 className="who-knows">Who owns</h1>
            <h2 className="heading">Who Is Bitcoin Satoshi Tokens?</h2>
            <div className="image-container">
              <img
                src={heroimg}
                alt="Header"
                className="responsive-image"
                widt
              />
            </div>
          </div>
        </div>

        <p className="description">
          WIBS tokens, a new cryptocurrency making waves in the financial
          markets, have caught the attention of some of the biggest names in the
          entertainment industry. Discover who these stars are, why they're
          investing in WIBS, and what this means for the future of
          cryptocurrency.
        </p>
        <div
          className=""
          style={{
            padding: "20px",
            maxWidth: "1980px",
            width: "100%",
            margin: "auto",
            marginTop: "100px",
          }}
        >
          <div className="row ">
            {cards.map((card, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4"
              >
                <CardComponent
                  name={card.name}
                  amount={card.amount}
                  icon1={twitter}
                  icon2={ether}
                  image={card.image}
                  twitterLink={card.twitter}
                  etherscanLink={card.etherscan}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <button className="btn more-btn">Load More</button>
        </div> */}
      </div>
    </Layout>
  );
};

export default FanPage;
