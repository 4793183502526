import React, { useState } from "react";
import styled from "styled-components";
// import UploadIcon from "@mui/icons-material/Upload";

// Styled Components
const Container = styled.div`
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UploadBox = styled.div`
  max-width: 400px;
  height: 400px;
  width: 100%;
  border: 1px solid #fa8d01;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  color: #fa8d01;
  position: relative;
  &:hover {
    background-color: #1a1a1a;
  }
`;

const UploadText = styled.p`
  margin-top: 8px;
  color: #fa8d01;
  font-size: 16px;
  text-align: center;
`;

const FileInput = styled.input`
  position: absolute;
  width: 300px;
  height: 300px;
  opacity: 0;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0px;
  gap: 10px;
  width: 100%;
  max-width: 400px;
`;

const UploadComponent = () => {
  const [file, setFile] = useState(null);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <Container>
      {/* Upload Box */}
      <UploadBox onDrop={handleDrop} onDragOver={handleDragOver}>
        {/* <UploadIcon fontSize="large" /> */}
        <UploadText>
          {file ? file.name : "Upload photo\nOr, drag an image here"}
        </UploadText>
        <FileInput type="file" accept="image/*" onChange={handleFileUpload} />
      </UploadBox>

      {/* Buttons */}
      <ButtonContainer>
        <button
          style={{
            background: "none",
            border: "1px solid #FA8D01",
            color: "white",
            flex: 1,
            padding: "15px 15px",
            cursor: "pointer",
          }}
        >
          Claim Reward
        </button>
        <button
          style={{
            background: "#FA8D01",
            border: "none",
            flex: 1,
            padding: "15px 15px",
            cursor: "pointer",
          }}
        >
          Submit
        </button>
      </ButtonContainer>
    </Container>
  );
};

export default UploadComponent;
