import React, { useState, useEffect } from "react";

const GameComponent = ({ matches, timeRemaining, setTimeRemaining }) => {
  const [playerName, setPlayerName] = useState("");
  // Timer starts at 60 seconds
  const [gameStarted, setGameStarted] = useState(false);

  useEffect(() => {
    let timer;
    if (gameStarted && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [gameStarted, timeRemaining]);

  const handlePlay = () => {
    setGameStarted(true);
    setTimeRemaining(60); // Reset the timer
  };

  const handleNewGame = () => {
    setGameStarted(false);
    setTimeRemaining(60); // Reset the timer
  };

  const getWIBSReward = () => {
    if (matches >= 1 && matches <= 5) return 50;
    if (matches >= 6 && matches <= 10) return 100;
    if (matches >= 11 && matches <= 15) return 150;
    if (matches >= 16 && matches <= 20) return 200;
    if (matches >= 21 && matches <= 25) return 250;
    if (matches >= 26 && matches <= 50) return 500;
    return 0; // Default if matches are outside the range
  };

  return (
    <div
      style={{
        backgroundColor: "#0a0f27",
        color: "#ffffff",
        maxWidth: "320px",
        width: "100%",
        fontFamily: "Arial, sans-serif",
        borderRadius: "10px",
        marginTop: "10px",
      }}
    >
      <input
        type="text"
        placeholder="Player Name"
        value={playerName}
        onChange={(e) => setPlayerName(e.target.value)}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          border: "none",
          borderRadius: "5px",
        }}
      />
      <button
        onClick={handlePlay}
        style={{
          width: "100%",
          backgroundColor: "#fa8d01",
          color: "#ffffff",
          padding: "10px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Play
      </button>

      <div
        style={{
          border: "1px solid #fa8d01",
          borderRadius: "5px",
          padding: "20px",
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        <h1 style={{ fontSize: "2rem" }}>{timeRemaining}s</h1>
        <p>Time remaining</p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <p>{matches} Matches</p>
        </div>

        <p style={{ fontSize: "1.2rem", fontWeight: "bold", color: "#fa8d01" }}>
          {getWIBSReward()} WIBS
        </p>
        <p>Reward</p>
      </div>

      <button
        onClick={handleNewGame}
        disabled={gameStarted && timeRemaining > 0}
        style={{
          width: "100%",
          background: "none",
          color: gameStarted && timeRemaining > 0 ? "#999" : "#fa8d01",
          padding: "10px",
          border: "1px solid #fa8d01",
          borderRadius: "5px",
          cursor: gameStarted && timeRemaining > 0 ? "not-allowed" : "pointer",
          marginBottom: "10px",
        }}
      >
        New Game
      </button>

      <button
        disabled={gameStarted && timeRemaining > 0}
        style={{
          width: "100%",
          background: "none",
          color: gameStarted && timeRemaining > 0 ? "#999" : "#fa8d01",
          padding: "10px",
          border: "1px solid #fa8d01",
          borderRadius: "5px",
          cursor: gameStarted && timeRemaining > 0 ? "not-allowed" : "pointer",
        }}
      >
        Claim Reward
      </button>
    </div>
  );
};

export default GameComponent;
