import React, { useEffect, useState } from "react";
import styled from "styled-components";
import coinLogo from "../../../assets/img/coin/WIBS.svg";
import usdtLogo from "../../../assets/img/coin/USDT.svg";
import ethLogo from "../../../assets/img/coin/ETH.svg";
import usdLogo from "../../../assets/img/coin/USD.svg";
import {
  buyTokensWithETH,
  buyTokensWithUSDT,
  getWeb3,
} from "../../../smartcontract/web3Service/web3Service.js";
import usdtABI from "../../../smartcontract/USDTAbi.json";
import { ethers } from "ethers";
import contractABI from "../../../smartcontract/ABI.json";
import TimerCountDown from "../CountDownTimer/index.js";
import { useWeb3React } from "@web3-react/core";
import {
  injected,
  walletconnect,
  coinbaseWallet,
} from "../../../smartcontract/walletConnect.js";
import WalletButtons from "./walletConnectButtcons.js";

const Container = styled.div`
  text-align: center;
  max-width: 700px;
`;

const Heading = styled.h1`
  font-size: 24px;
  color: black;
`;

const SubHeading = styled.h2`
  font-size: 18px;
  color: black;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: black;
`;

const Line = styled.hr`
  width: 50%;
  margin: 20px auto;
`;

const Text = styled.p`
  font-size: 16px;
  text-align: center;
  color: black;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
const Button1 = styled.button`
  width: 100%;
  margin: 10px 10px 10px 0px;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  background: #f59109;
  color: black;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  gap: 5px;
`;
const Button2 = styled.button`
  width: 100%;
  margin: 10px 0px 10px 10px;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border: none;
  background: #ffe7b2;
  color: black;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const FullWidthButton = styled(Button1)`
  margin: 10px 0px 20px 0px;
  color: black;
  background: #ffe7b2;
  border-radius: 5px;
`;

const SmallHeading = styled.h3`
  font-size: 12px;
  color: black;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  margin-bottom: 10px;
`;

const InputField = styled.input`
  flex: 1;
  padding: 10px;
  border: none;
  background: none;
  width: 80%;
  outline: none;
  border: none;
`;

const Logo = styled.img`
  width: 30px;
`;

const BuyNowButton = styled(Button1)`
  width: 100%;
  background: black;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 0px;
  &:hover {
    background: #272727;
  }
`;

const Form = ({ setParentCB }) => {
  const { activate, library, account, active } = useWeb3React();
  const [ethAmount, setEthAmount] = useState("");
  const [usdtAmount, setUsdtAmount] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("ETH");
  const [provider, setProvider] = useState(null);
  const [wibsBalance, setWibsBalance] = useState("0");
  const [wibsReceived, setWibsReceived] = useState("0");
  const [isValidAmount, setIsValidAmount] = useState(true);
  const [userAddress, setUserAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionUrl, setTransactionUrl] = useState("");
  const [ethPrice, setEthPrice] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const usdtTokenAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
  const contractAddress = "0xa716004BCD80247e5b9E5E77F8fd4af87B85f1c1";

  useEffect(() => {
    const init = async () => {
      try {
        if (!active && window.ethereum) {
          await activate(injected);
        }
        if (library) {
          setProvider(library);
          await fetchEthPrice(library);
          const signer = library.getSigner();
          const address = await signer.getAddress();
          setUserAddress(address);
        }
      } catch (err) {
        console.log("Error during initialization:", err);
      }
    };
    init();
  }, [library, activate, active]);

  // Fetch ETH price from the contract
  const fetchEthPrice = async (provider) => {
    if (!provider) return;
    const contract = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );
    const price = await contract.getLatestEthPrice();
    setEthPrice(parseFloat(ethers.utils.formatUnits(price, "mwei"))); // Convert to a human-readable format if necessary
  };

  // Validate minimum purchase amount
  useEffect(() => {
    if (ethAmount) {
      const usdValue = (ethPrice * parseFloat(ethAmount)) / 100;
      let wisbReceived = usdValue / 0.00021;
      setWibsReceived(wisbReceived.toFixed(2));
      if (usdValue < 50) {
        setIsValidAmount(false);
        setErrorMessage("Minimum purchase amount is $50 USD equivalent.");
      } else {
        setIsValidAmount(true);
        setErrorMessage("");
        //calculateWibsReceived(ethAmount, 'ETH');
      }
    } else if (usdtAmount) {
      let wisbReceived = usdtAmount / 0.00021;
      setWibsReceived(wisbReceived.toFixed(2));
      if (usdtAmount < 50) {
        setIsValidAmount(false);
        setErrorMessage("Minimum purchase amount is $50 USD equivalent.");
      } else {
        setIsValidAmount(true);
        setErrorMessage("");
        //calculateWibsReceived(usdtAmount, 'USDT');
      }
    }
  }, [ethAmount, usdtAmount, ethPrice, selectedCurrency]);

  // Calculate the number of WIBS received
  const calculateWibsReceived = async (amount, currency) => {
    if (!provider) return;
    const contract = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );
    let tokens;
    if (currency === "ETH") {
      tokens = await contract.getTokensForEth(ethers.utils.parseEther(amount));
    } else {
      tokens = await contract.getTokensForUsdt(
        ethers.utils.parseUnits(amount, 6)
      );
    }
    setWibsReceived(ethers.utils.formatUnits(tokens));
  };

  // Function to approve USDT spend
  const approveUsdtSpend = async () => {
    if (!provider || !usdtAmount) return;
    const signer = provider.getSigner();
    const usdtContract = new ethers.Contract(usdtTokenAddress, usdtABI, signer);
    const numTokens = ethers.utils.parseUnits(usdtAmount, 6);

    try {
      const tx = await usdtContract.approve(contractAddress, numTokens);
      const receipt = await tx.wait();
      console.log("Approval transaction receipt:", receipt);
      return true;
    } catch (error) {
      console.error("Error in USDT approve:", error);
      return false;
    }
  };

  const fetchWibsBalance = async () => {
    if (library && account) {
      const contract = new ethers.Contract(
        contractAddress,
        contractABI,
        library.getSigner(account)
      );
      const balance = await contract.balanceOf(account);
      setWibsBalance(ethers.utils.formatUnits(balance, 18));
    }
  };

  const handleConnectWallet = async () => {
    if (!active && window.ethereum) {
      try {
        await activate(injected);
        console.log("Wallet connected successfully!");
        await fetchWibsBalance();
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    }
  };

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    // Clear other input when one currency is selected
    if (currency === "ETH") {
      setUsdtAmount("");
    } else {
      setEthAmount("");
    }
  };

  const handleBuyNow = async () => {
    setLoading(true);
    try {
      if (selectedCurrency === "ETH" && ethAmount) {
        const txResponse = await buyTokensWithETH(provider, ethAmount);

        const receipt = await txResponse.wait();
        setTransactionUrl(
          `https://sepolia.etherscan.io/tx/${receipt.transactionHash}`
        );
        setParentCB(
          `https://sepolia.etherscan.io/tx/${receipt.transactionHash}`
        );
        fetchWibsBalance();
      } else if (selectedCurrency === "USDT" && usdtAmount) {
        let res = await approveUsdtSpend();
        if (res) {
          const txResponse = await buyTokensWithUSDT(provider, usdtAmount);

          const receipt = await txResponse.wait();
          setTransactionUrl(
            `https://sepolia.etherscan.io/tx/${receipt.transactionHash}`
          );
          setParentCB(
            `https://sepolia.etherscan.io/tx/${receipt.transactionHash}`
          );
          fetchWibsBalance();
        } else {
          alert("Approve USDT failed");
        }
      }
    } catch (error) {
      console.error("Transaction Error:", error);
      console.error("Transaction Error:", error.code);
      if (
        error.code === -32603 ||
        error.message.includes("insufficient funds")
      ) {
        alert("You do not have enough ETH to cover the gas cost and value.");
      } else {
        alert(`Error: ${error.message}`); // Displaying generic error message
      }
    }
    setLoading(false);
  };

  const handleBuyWithFiat = () => {
    let getSignInToken = localStorage.getItem("signInToken");
    if (getSignInToken) {
      window.location.href = `https://cex.indexx.ai/update/home?buyToken=WIBS&signInToken=${getSignInToken}`;
    } else {
      window.location.href = "https://cex.indexx.ai/update/home?buyToken=WIBS";
    }
  };

  const handlebuyIndex = () => {
    let getSignInToken = localStorage.getItem("signInToken");
    if (getSignInToken) {
      window.location.href = `https://cex.indexx.ai/update/home?buyToken=WIBS&signInToken=${getSignInToken}`;
    } else {
      window.location.href = "https://cex.indexx.ai/update/home?buyToken=WIBS";
    }
  };
  return (
    <>
      <Container>
        <Heading>
          {/* Who Is Bitcoin Satoshi Coin launches Bitcoin Halving day! Last chance
          to buy! */}
          Buy Now the price will increase on february 2025
        </Heading>
        <div style={{ margin: "10px auto 20px auto" }}>
          <TimerCountDown />
        </div>
        <SubHeading>
          The presale will continue until we raise over 10M USD , at which point
          we will launch the Bitcoin Satoshi claim.
        </SubHeading>
        <Paragraph>Your purchased WIBS = {wibsBalance}</Paragraph>
        <Paragraph>Your stakeable WIBS = 0</Paragraph>
        <div style={{ margintop: "30px" }}></div>
        <p
          style={{
            color: "black",
            // borderBottom: "1px solid black",
          }}
        >
          {" "}
          <span
            style={{
              background: "#FFAF00",
              width: "fit-content",
              height: "15px",
              lineHeight: 0,
              padding: "0px 20px",
              margin: 0,
              marginBottom: "-40px",
              display: "inline-block",
              transform: "translateY(12px)",
              fontSize: "20px",
            }}
          >
            <strong>1 Who Is Bitcoin Satoshi / WIBS = $0.00021</strong>
          </span>
        </p>
        {/* {!userAddress && <WalletButtons setProvider={setProvider} />} */}
        {!account ? (
          <>
            {/* <BuyNowButton onClick={handleConnectWallet}>
              Connect to Metamask Wallet
            </BuyNowButton> */}
            <BuyNowButton onClick={handlebuyIndex}>
              Buy from Indexx Exchange
            </BuyNowButton>
            {/* <span style={{ textAlign: "center", color: "black" }}>OR</span> */}
            <p
              style={{
                color: "black",
                borderBottom: "0.5px solid #4C4C4C",
                marginBottom: "15px",
              }}
            >
              {" "}
              <span
                style={{
                  background: "#FFAF00",
                  width: "fit-content",
                  height: "15px",
                  lineHeight: 0,
                  padding: "0px 20px",
                  margin: 0,
                  marginBottom: "-40px",
                  display: "inline-block",
                  transform: "translateY(12px)",
                }}
              >
                OR
              </span>
            </p>
            <WalletButtons setProvider={setProvider} />
          </>
        ) : (
          <>
            <Paragraph>
              Connected:{" "}
              {`${userAddress.slice(0, 6)}...${userAddress.slice(-4)}`}
            </Paragraph>

            <ButtonContainer>
              <Button1
                onClick={() => handleCurrencyChange("ETH")}
                style={{
                  background:
                    selectedCurrency === "ETH" ? "#f59109" : "#ffe7b2",
                }}
              >
                <span>
                  <Logo src={ethLogo} />
                </span>
                ETH
              </Button1>
              <Button2
                onClick={() => handleCurrencyChange("USDT")}
                style={{
                  background:
                    selectedCurrency === "USDT" ? "#f59109" : "#ffe7b2",
                }}
              >
                <span>
                  <Logo src={usdtLogo} />
                </span>
                USDT
              </Button2>
            </ButtonContainer>

            <FullWidthButton onClick={handleBuyWithFiat}>
              {" "}
              <span>
                <Logo src={usdLogo} />
              </span>
              FIAT
            </FullWidthButton>
            <SmallHeading>
              Buy WIBS with FIAT using Indexx Exchange
            </SmallHeading>
            <div style={{ display: "flex" }}>
              <InputContainer style={{ marginRight: "20px" }}>
                <label>
                  Pay with <strong>{selectedCurrency}</strong>
                </label>
                <div
                  style={{
                    display: "flex",
                    background: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <InputField
                    type="text"
                    value={selectedCurrency === "ETH" ? ethAmount : usdtAmount}
                    onChange={(e) =>
                      selectedCurrency === "ETH"
                        ? setEthAmount(e.target.value)
                        : setUsdtAmount(e.target.value)
                    }
                    placeholder="Enter ETH amount"
                  />
                  <Logo
                    src={selectedCurrency === "ETH" ? ethLogo : usdtLogo}
                    alt="Logo"
                  />
                </div>
                {!isValidAmount && (
                  <Paragraph style={{ color: "red" }}>{errorMessage}</Paragraph>
                )}
              </InputContainer>
              <InputContainer>
                <label>
                  Receive: <strong>WIBS</strong>
                </label>
                <div
                  style={{
                    display: "flex",
                    background: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <InputField type="text" value={wibsReceived} readOnly />
                  <Logo src={coinLogo} alt="USDT Logo" />
                </div>
              </InputContainer>
            </div>

            <BuyNowButton
              onClick={handleBuyNow}
              disabled={!isValidAmount || loading || !userAddress}
            >
              {loading ? "Loading..." : "Buy Now"}{" "}
            </BuyNowButton>
            {transactionUrl && (
              <p>
                View Transaction:{" "}
                <a
                  href={transactionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Etherscan
                </a>
              </p>
            )}
          </>
        )}
        <Text>Powered by: inWeb3</Text>
      </Container>
    </>
  );
};

export default Form;
