import React from "react";
import "./style.css";
import closeWhite from "../../../../assets/img/cross_white.png";
import Logo from "../../../../assets/img/popupLogo.svg";
const Modal = ({ show = true, onClose, img, type }) => {
  return (
    show && (
      <div className="backdrops">
        <div className="modalsss">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              className="close-buttons"
              src={closeWhite}
              style={{ height: "16px" }}
              onClick={onClose}
            />
          </div>
          <div className="game-mechanics">
            <h1 className="title" style={{ fontFamily: "mansalva" }}>
              Game Mechanics:
            </h1>

            <section className="mechanic">
              <h2>1. Photo Upload:</h2>
              <ul>
                <li>
                  Players upload a photo they believe may match one of the
                  wanted suspects in the database.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>2. Image Matching:</h2>
              <ul>
                <li>
                  The game's system utilizes image recognition technology to
                  compare the uploaded photo with the images of wanted suspects
                  in the database.
                </li>
                <li>
                  If the uploaded photo matches a wanted suspect's image, the
                  player receives a notification.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>3. Report Submission:</h2>
              <ul>
                <li>
                  Players review the details of the potential match and decide
                  whether to submit a report.
                </li>
                <li>
                  Reports include the player's assessment of the match and any
                  additional information they may have.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>4. Verification Process:</h2>
              <ul>
                <li>
                  Submitted reports are reviewed by game administrators or law
                  enforcement authorities for verification.
                </li>
                <li>
                  Valid matches are confirmed, and players receive rewards
                  accordingly.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>5. Rewards and Incentives:</h2>
              <ul>
                <li>
                  Players earn rewards for successfully matching uploaded photos
                  with wanted suspects.
                </li>
                <li>
                  Rewards may include virtual currency, experience points,
                  badges, or other in-game incentives.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>6. Accuracy Feedback:</h2>
              <ul>
                <li>
                  Players receive feedback on the accuracy of their reports and
                  their overall performance in the game.
                </li>
                <li>
                  Feedback may include tips for improving matching accuracy and
                  solving future cases.
                </li>
              </ul>
            </section>
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              maxWidth: "400px",
              width: "100%",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                background: "none",
                border: "1px solid #FA8D01",
                color: "white",
                flex: 1,
                padding: "15px 15px",
                cursor: "pointer",
              }}
            >
              Play the game
            </button>
            <button
              style={{
                background: "#FA8D01",
                border: "none",
                flex: 1,
                padding: "15px 15px",
                cursor: "pointer",
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
