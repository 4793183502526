import React from "react";
import "./style.css";

const CardComponent = ({
  name,
  amount,
  icon1,
  icon2,
  image,
  twitterLink,
  etherscanLink,
}) => {
  return (
    <div className="card-component" style={{ overflow: "hidden" }}>
      <div className="card-info">
        <h3 className="card-name">{name}</h3>
        {amount !== "" && <p className="card-amount">Amount: {amount} +WIBS</p>}

        <div className="card-icons">
          {amount !== "" && (
            <>
              <img
                src={icon1}
                alt="Icon 1"
                className="card-icon"
                onClick={() => window.open(twitterLink, "_blank")}
              />
              <img
                src={icon2}
                alt="Icon 2"
                className="card-icon"
                onClick={() => window.open(etherscanLink, "_blank")}
              />
            </>
          )}
        </div>
      </div>
      <img src={image} alt={name} className="card-image" />
    </div>
  );
};

export default CardComponent;
