import React from "react";
import "./style.css";
import closeWhite from "../../../../assets/img/cross_white.png";
import Logo from "../../../../assets/img/popupLogo.svg";
const Modal = ({ show = true, onClose, img, type }) => {
  return (
    show && (
      <div className="backdrops">
        <div className="modalss">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              className="close-buttons"
              src={closeWhite}
              style={{ height: "16px" }}
              onClick={onClose}
            />
          </div>
          <div className="game-mechanicss">
            <h1 className="title" style={{ fontFamily: "mansalva" }}>
              Game Mechanics:
            </h1>

            <section className="mechanic">
              <h2>1. Game Setup:</h2>
              <ul>
                <li>
                  The game board consists of a grid of face-down cards, each
                  containing different images related to Satoshi Nakamoto or
                  Bitcoin.
                </li>
                <li>
                  The number of cards can vary based on difficulty levels, with
                  more cards increasing the challenge.
                </li>
                <li>
                  Players begin by selecting a difficulty level (easy, medium,
                  hard) before starting the game.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>2. Objective:</h2>
              <ul>
                <li>
                  The objective is to match pairs of identical cards by flipping
                  them over and remembering their positions on the board.
                </li>
                <li>
                  Players win the game by successfully matching all pairs within
                  a set number of attempts.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>3. Turn-Based Gameplay:</h2>
              <ul>
                <li>Players take turns flipping over two cards at a time.</li>
                <li>
                  If the two flipped cards match, they remain face-up, and the
                  player earns points.
                </li>
                <li>
                  If the cards do not match, they are flipped back face-down,
                  and the player's attempt count increases.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>4. Scoring:</h2>
              <ul>
                <li>Players earn points for each successful match.</li>
                <li>
                  Additional points may be awarded for completing the game
                  within a certain number of attempts or within a time limit,
                  depending on the chosen difficulty level.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>5. Game Completion:</h2>
              <ul>
                <li>
                  The game ends when all pairs have been successfully matched,
                  when the player runs out of time and attempts.
                </li>
                <li>
                  Players receive a final score based on the number of matches
                  made and any bonus points earned.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>6. Leaderboard and Progress Tracking:</h2>
              <ul>
                <li>
                  The game may include a leaderboard to track high scores and
                  achievements.
                </li>
                <li>
                  Progress tracking allows players to see their improvement over
                  time and encourages replayability.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>7. Feedback and Rewards:</h2>
              <ul>
                <li>
                  Players receive feedback throughout the game, such as
                  encouraging messages for successful matches or hints for
                  uncovering pairs.
                </li>
                <li>
                  Upon completing the game, players may receive virtual rewards
                  or unlock new levels or features.
                </li>
              </ul>
            </section>

            <section className="mechanic">
              <h2>8. Accessibility Features:</h2>
              <ul>
                <li>
                  The game should be designed with accessibility in mind,
                  including options for adjusting difficulty levels, providing
                  audio cues, and accommodating color-blind players.
                </li>
              </ul>
            </section>
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              maxWidth: "400px",
              width: "100%",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                background: "none",
                border: "1px solid #FA8D01",
                color: "white",
                flex: 1,
                padding: "15px 15px",
                cursor: "pointer",
              }}
            >
              Play the game
            </button>
            <button
              style={{
                background: "#FA8D01",
                border: "none",
                flex: 1,
                padding: "15px 15px",
                cursor: "pointer",
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
